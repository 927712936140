<template>
    <div class="w-full">
		<SiteHeader />
		<section id="benefits" class="w-full py-16 bg-siteRed-100">
			<div class="container mx-auto">
				<h2 class="mb-4 text-6xl leading-10 text-center text-white lg:text-7xl font-cormorant">Benefits</h2>
				<h3 class="text-2xl font-thin text-center text-white font-roboto">of Thermage FLX</h3>
			</div>
			<div class="container pt-8 mx-auto">
				<div class="grid items-start grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-6">
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-01.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-10/12">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">Non-invasive <br class="hidden lg:block" />& Potent</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">Thermage works with your skin’s natural mechanism</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-02.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">One-Time Treatment</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">A single treatment gives lasting results</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-03.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">Patient Comfort</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">Auto-tuned radiofrequency pulsations and cooling make treatments a breeze</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-04.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">Little to No Downtime</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">Minimal side effects that do not interrupt your day-to-day life</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-12.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-10/12">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">Multiple <br class="sm:hidden">Treatment <br class="sm:hidden">Areas</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">Target troubled spots anywhere from forehead to legs</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-05.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-10/12">
							<h2 class="text-2xl font-light leading-tight text-center text-white alg:text-3xl font-roboto">Lasting <br class="sm:hidden">& <br class="sm:hidden">Preventative</h2>
							<h3 class="text-lg font-thin leading-tight text-center text-white lg:text-xl mt-2 lg:mt-4">Potent tech that works hard for results that last 1-2 years</h3>
						</div>
					</div>
					
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto">
				<div class="w-full mx-auto mb-10 text-center">
					<h2 class="mb-2 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Target Signs of Ageing</h2>
					<span class="text-2xl text-siteRed-100">With Effective Anti-Ageing & Natural Healing</span>
				</div>
				<div class="grid items-start grid-cols-1 gap-y-10 md:gap-x-10 md:grid-cols-2 lg:grid-cols-4">
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-01m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-01.jpg">
								<img src="/images/vavavoom-img-01.jpg" class="mb-6 rounded-xl mx-auto" loading="lazy" />
							</picture>
							<h2 class="text-xl font-light leading-tight text-center text-black md:text-2xl lg:text-3xl font-roboto">Wrinkles & Folds</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-02m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-02.jpg">
								<img src="/images/vavavoom-img-02.jpg" class="mb-6 rounded-xl mx-auto" loading="lazy" />
							</picture>
							<h2 class="text-xl font-light leading-tight text-center text-black md:text-2xl lg:text-3xl font-roboto">Loss of Volume</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-03m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-03.jpg">
								<img src="/images/vavavoom-img-03.jpg" class="mb-6 rounded-xl mx-auto" loading="lazy" />
							</picture>
							<h2 class="text-xl font-light leading-tight text-center text-black md:text-2xl lg:text-3xl font-roboto">Loss of Elasticity</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-04m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-04.jpg">
								<img src="/images/vavavoom-img-04.jpg" class="mb-6 rounded-xl mx-auto" loading="lazy" />
							</picture>
							<h2 class="text-xl font-light leading-tight text-center text-black md:text-2xl lg:text-3xl font-roboto">Lack of Skin Clarity</h2>
						</div>
					</div>
				</div>
				<div class="my-12 border-b border-siteRed-200"></div>
				<div class="flex flex-wrap items-center justify-between">
					<div class="w-full mb-10 lg:w-3/5 lg:mb-0">
						<h3 class="text-2xl font-roboto mb-6">With a passion for reverse-ageing, Dr Vanessa Phua can help you rewind the clock & regain your youthful visage.</h3>
						<h3 class="font-roboto text-base font-medium">(Thermage is a medical-use only device that should only be performed by an MOH-accredited medical professional like Dr Vanessa Phua.)</h3>
					</div>
					<div class="items-end justify-end w-full lg:w-2/5">
						<div class="items-end float-left lg:float-right"><a href="#enquire" class="flex flex-wrap items-center px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 lg:float-left bg-siteRed hover:underline">Enquire Now <img src="/images/arrow-icon.png" class="h-6 ml-4" /></a></div>
					</div>
				</div>
			</div>
		</section>
		<section class="relative flex flex-wrap lg:pb-0 bg-siteRed-100">
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 lg:w-3/5 lg:py-24 lg:pr-8 xl:py-32">
					<div class="mb-10">
						<h2 class="mb-2 text-5xl leading-none text-white lg:text-7xl font-cormorant">Thermage</h2>
						<span class="text-2xl text-white">With Dr Vanessa Phua</span>
					</div>
					<p class="text-white">Dr Vanessa Phua is a certified practitioner in Thermage treatments.</p>
					<p class="text-white">She examines each patient’s skin needs with a meticulous eye during personal consultations and tailors customised Thermage treatment plans to meet each patient’s desired outcome.</p>
					<p class="text-white">Thermage uses radiofrequency technology to heat the deep, collagen-rich layers of the skin, which causes existing collagen to remodel and new cells to be created. The heat also causes water molecules to detach from existing collagen, resulting in an immediate contraction which manifests as skin tightening.</p>
					<p class="text-white">With Dr Phua’s experience and attention to detail, patients can rest assured that all Thermage treatments performed at the clinic are done with their safety and comfort in mind.</p>
				</div>
				<div id="drvanessaphua" class="w-0 h-0"></div>
				<div class="right-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:absolute lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua.jpg')"></div>
			</div>
			<img src="/images/dr-vanessa-phua.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="relative flex flex-wrap w-full pb-12 lg:pb-0">
			<div class="absolute left-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua-photo.jpg')"></div>
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 ml-auto lg:w-3/5 lg:py-24 lg:pl-16 xl:py-32">
					<div class="mb-10">
						<span class="mb-4 text-2xl text-siteRed-100">Doctor Behind Your</span>
						<h2 class="text-5xl leading-none text-siteRed-100 lg:text-7xl font-cormorant">Beauty</h2>
					</div>
					<p>Dr Vanessa Phua was trained as a physician in the United Kingdom with a specialization in ophthalmological surgery and aesthetic medicine. Having gained  rigorous and multifaceted training in the arena of aesthetic medicine, Dr Vanessa Phua turns her doctorly expertise these days to helping her patients age gracefully. At her clinic, you may select from signature treatments like Thermage FLX, Papillon Revitalizing Threadlift and Joie de Vivre contouring.</p>
					<p>With her keen eye for beauty, Dr Phua can advise you on the best treatment plan to address your concerns.</p>
					<p class="font-medium">Qualifications</p>
					<ul class="pl-4 mb-6 leading-relaxed list-disc">
						<li class="pb-2 pl-2">BMed Science, BMBS (Nottingham, UK)</li>
						<li class="pb-2 pl-2">MRCS (Edinburgh), MMED (S'pore) Ophthalmology</li>
						<li class="pb-2 pl-2">Dip Practical Dermatology (Cardiff, Wales, UK)</li>
						<li class="pb-2 pl-2">Registered/Qualified Laser Physician</li>
						<li class="pb-2 pl-2">Member of the Society of Aesthetic Medicine, Singapore</li>
						<li class="pb-2 pl-2">Member of the International Academy of Cosmetic Dermatology Surgical</li>
						<li class="pb-2 pl-2">Fellow of the American Society for Laser Medicine & Surgery</li>
						<li class="pb-2 pl-2">Thread Lift Practitioner (Duo Lift, Serdev, Silhouette, Silhouette Soft, Ultra V Lift)</li>
						<li class="pb-2 pl-2">Filler Rejuvenation for Body & Face Contouring Certified</li>
						<li class="pb-2 pl-2">Filler Rejuvenation For Body & Face Contouring Certified</li>
						<li class="pb-2 pl-2">Thermage certified Physician</li>
						<li class="pb-2 pl-2">Ulthera (Focused Ultrasound Skin Lifting) Certified Physician & Trainer</li>
						<li class="pb-2 pl-2">Certified Regional Trainer to Aesthetic Physicians for Filler Rejuvenation with Teoxane Laboratories (Geneva)</li>
						<li class="pb-2 pl-2">Certified Trainer for ENERPEELTM, Patented Facial Chemoexfoliation with Tebitech Laboratories (Italy)</li>
					</ul>
				</div>
			</div>
			<img src="/images/dr-vanessa-phua-photo.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="w-full bg-opacity-0 bg-grad lg:bg-opacity-100">
			<div class="container mx-auto">
				<div class="flex flex-wrap">
					<div class="w-full px-6 pb-16 lg:py-16 lg:w-3/5 bg-siteRed-400 lg:pr-20">
						<h2 class="mb-12 text-4xl font-normal leading-none sm:text-5xl lg:text-[3.9rem] 2xl:text-[4.4rem] font-cormorant"><span class="text-siteRed">Thermage</span> utilizes radiofrequency to activate the skin’s own collagen production for a more youthful appearance that <span class="text-siteRed">lasts 1 to 2 years.</span></h2>
						<a href="#enquire" class="flex flex-wrap items-center float-left px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 bg-siteRed hover:underline ">Enquire Now <img src="/images/arrow-icon.png" class="h-6 ml-4" /></a>
					</div>
					<div id="thermage" class="w-full px-6 py-16 lg:w-2/5 bg-siteRed-100 lg:pl-0 lg:pl-20">
						<h3 class="mb-16 text-2xl leading-normal text-white lg:text-4xl">What to Expect from a Typical Thermage Session</h3>
						<div class="flex flex-wrap gap-y-10">
							<div class="w-full md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-06.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-xl font-normal leading-none text-white">Duration:</span><br/><span class="text-lg text-white">30 – 90 mins</span></div>
								</div>
							</div>
							<div class="w-full md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-08.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-xl font-normal leading-none text-white">Downtime:</span><br/><span class="text-lg text-white">Little to None</span></div>
								</div>
							</div>
							<div class="w-full md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-10.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-xl font-normal leading-none text-white">Results:</span><br/><span class="text-lg text-white">Results appear gradually over 2 to 6 months</span></div>
								</div>
							</div>
							<div class="w-full md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-07.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-xl font-normal leading-none text-white">Comfort Level:</span><br/><span class="text-lg text-white">Low heat with cooling and vibrating effects to minimise discomfort</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16 bg-siteRed-200">
			<div class="container mx-auto">
				<h2 class="mb-10 text-6xl text-center text-black lg:text-7xl font-cormorant">FAQ</h2>
				<div>
					<div class="mb-2">
						<div @click="index = 0" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">How long do the results last?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 0" class="p-8">
								<p class="mb-4 text-black">Results last up to 2 years depending on the patient’s skin health and their natural ageing process after treatment.</p>
							</div>
						</collapse-transition>
					</div>
					<div class="mb-2">
						<div @click="index = 1" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">How many treatments are recommended?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 1" class="p-8">
								<p class="mb-4 text-black">One session is sufficient to deliver full benefits of Thermage FLX. Annual treatments of Thermage works to maintain results.</p>
							</div>
						</collapse-transition>
					</div>
					<div class="mb-2">
						<div @click="index = 2" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">What about pain and side effects?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 2 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 2" class="p-8">
								<p class="mb-4 text-black">Slight heat may be felt at the treatment areas. However, Thermage FLX has been designed to minimise discomfort through its concurrent vibration and cooling action on the skin.</p>
								<p class="mb-4 text-black">Side effects are also minimal with this procedure – some temporary redness and swelling which usually dissipates after a day.</p>
							</div>
						</collapse-transition>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto text-center"><a name="enquire"></a>
				<div class="w-full mx-auto lg:w-3/4">
					<h2 class="mb-10 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Experience self-care with a <br class="hidden sm:inline lg:hidden xl:inline" />natural facelift today.</h2>
					<span class="text-2xl text-siteRed-100">Fill up the form to find out more about natural anti-ageing <br class="hidden sm:inline">by a certified Thermage physician.</span>
				</div>
				<div class="w-full mx-auto mt-10 lg:w-3/4">
					<ContactForm />
					<!-- <iframe allowTransparency="true" style="min-height:450px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="//feedback.activamedia.com.sg/my-contact-form-5756594.html"></iframe> -->
				</div>
			</div>
		</section>
		<section class="flex flex-wrap">
			<div class="w-full bg-center bg-no-repeat bg-cover lg:w-1/2" style="background-image: url('/images/clinic-photo.jpg')">
				<img src="/images/clinic-photo.jpg" class="block lg:hidden" />
			</div>
			<div class="flex justify-start w-full lg:w-1/2 bg-siteRed-100">
				<div class="py-12 container-half lg:pl-16 lg:py-24">
					<h2 class="mb-10 text-6xl leading-none text-white lg:text-8xl font-cormorant">Our Clinic</h2>
					<p class="text-white">Established in 1999, The Chelsea Clinic is dedicated to the pursuit of aesthetic excellence and places a strong emphasis on the holistic approach to a patient’s well-being focuses through personalized health care.</p>
					<p class="text-white">Our mission is to provide safe, effective and affordable treatments to our clients without compromising on the quality of our work.</p>
				</div>
			</div>
    	</section>
        <SiteFooter />
    </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import ContactForm from '@/components/ContactForm.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		ContactForm,
		SiteFooter,
		CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
	},
}
</script>
<style>

@screen lg{
	.bg-grad{
		background: rgb(247,247,247);
		background: -moz-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#8a2224",GradientType=1); 
	}
}
</style>