<template>
    <div class="w-full">
        <div class="px-4 lg:py-6 lg:px-6 bg-siteRed-100">
            <div class="flex flex-row items-center justify-between lg:px-6">
                <div class="w-1/2">
                    <img src="/images/logo.svg" class="h-20" />
                </div>
                <div class="w-1/2">
                    <div class="flex flex-row items-end justify-end">
                        <img src="/images/call-icon.png" class="h-6 mr-2 lg:h-10" />
                        <div class="flex flex-col items-end justify-end text-right">
                            <span class="text-sm leading-3 text-right text-white lg:text-base font-roboto">Call us at</span>
                            <a href="tel:+6567358833" class="text-xl font-normal text-white lg:text-3xl font-roboto">6735 8833</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-16 bg-siteBg-400">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <h2 class="mb-12 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Confirmation Message</h2>
                    <p class="center">Thank you for your kind interest in Dr Vanessa Phua Aesthetic Clinic.</p>
                    <p class="mb-6 center">We will attend to your request as soon as possible.</p>
                    <div class="flex mt-12">
                        <router-link to="/" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteRed-100 hover:underline">Back To HomePage</router-link>
                    </div>
                </div>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'ThankYou',
	components: {
        SiteFooter,
	},
}
</script>