import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
import ThankYou from '../views/thank-you.vue'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter)

Vue.use(VueGtm, {id: 'GTM-M5CN7WD'});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/thank-you/',
    name: 'ThankYou',
    component: ThankYou
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router